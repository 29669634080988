import Resource from "./resource.js";
/**
 * https://www.keycloak.org/docs-api/15.0/rest-api/#_client_registration_policy_resource
 */
export class ClientPolicies extends Resource {
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}/client-policies",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
  /* Client Profiles */
  listProfiles = this.makeRequest({
    method: "GET",
    path: "/profiles",
    queryParamKeys: ["include-global-profiles"],
    keyTransform: {
      includeGlobalProfiles: "include-global-profiles"
    }
  });
  createProfiles = this.makeRequest({
    method: "PUT",
    path: "/profiles"
  });
  /* Client Policies */
  listPolicies = this.makeRequest({
    method: "GET",
    path: "/policies"
  });
  updatePolicy = this.makeRequest({
    method: "PUT",
    path: "/policies"
  });
}