import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/service/auth.service';

export const UNDEFINED_ROLE = "###undefined"
export const ROLE_SEP = "##"

@Component({
  selector: 'app-wbce-button',
  templateUrl: './wbce-button.component.html',
  styleUrls: ['./wbce-button.component.scss']
})
export class WbceButtonComponent implements OnInit {
  public buttonText = '';
  styles = {
    "text":"wbce-text",
    "outlined":"wbce-outlined",
    "contained":"wbce-contained",
    "toggle":"wbce-toggle",
    "menu-list":"wbce-menu-list",
    "linear-gradiant":"wbce-linear-gradiant"
  };
  rippleColor = { // voir si on peut utiliser les constantes css
    "text":"rgba(248, 249, 250, 0.1)",
    "outlined":"rgba(248, 249, 250, 0.1)",
    "contained":"rgba(255, 255, 255, 0.15)",
    "toggle":"rgba(255, 255, 255, 0.15)", // non définitif
    "menu-list":"rgba(248, 249, 250, 0.1)",
    "linear-gradiant":"rgba(248, 249, 250, 0.1)",
  }

  subs : Subscription[] = [];

	//@Input() color: string = '0068B4';
	@Input() type: string = 'text'; // default type=text
	@Output() btnClick = new EventEmitter<MouseEvent>();
	@Input() isDisabled = false;
  @Input() upperCase = false;
  @Input() selected = false;
  @Input() tooltip: string = "";
  @Input() role : string = UNDEFINED_ROLE;

  currentUser:User;
  tooltipWithAut:string = "";
  isDisabledByRole : boolean = false;


  private auth : AuthService = inject(AuthService);

	constructor() {
    this.tooltipWithAut = this.tooltip
  }

  ngOnInit(): void {
    this.subs.push(
      this.auth.currentUser$.subscribe(
        this.onUserChange.bind(this)
      )
    )

    if(this.auth.currentUser){
      this.currentUser = this.auth.currentUser;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.role ||changes.tooltip) { // also add this check
      this.setDisableState()
    }
  }

  onUserChange(user: User){
    this.currentUser = user;
    this.setDisableState()
  }

  setDisableState(){
    if(this.role !== UNDEFINED_ROLE){
      this.isDisabledByRole = !this.currentUser || !this.currentUser.hasAccess(this.role);
      if(this.isDisabledByRole){
        this.tooltipWithAut = this.currentUser  && this.currentUser.getTooltipAccess(this.role);
      } else {
        this.tooltipWithAut = this.tooltip
      }
    }
  }

  ngOnDestroy(){
    for(const s of this.subs){
      s.unsubscribe();
    }
  }

	onClick(event: MouseEvent) {
   // event.stopPropagation()

    this.btnClick.emit(event);
	}
}
