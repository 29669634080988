import Resource from "./resource.js";
export class AuthenticationManagement extends Resource {
  /**
   * Authentication Management
   * https://www.keycloak.org/docs-api/8.0/rest-api/index.html#_authentication_management_resource
   */
  //   Register a new required action
  registerRequiredAction = this.makeRequest({
    method: "POST",
    path: "/register-required-action"
  });
  // Get required actions. Returns a list of required actions.
  getRequiredActions = this.makeRequest({
    method: "GET",
    path: "/required-actions"
  });
  // Get required action for alias
  getRequiredActionForAlias = this.makeRequest({
    method: "GET",
    path: "/required-actions/{alias}",
    urlParamKeys: ["alias"],
    catchNotFound: true
  });
  getClientAuthenticatorProviders = this.makeRequest({
    method: "GET",
    path: "/client-authenticator-providers"
  });
  getAuthenticatorProviders = this.makeRequest({
    method: "GET",
    path: "/authenticator-providers"
  });
  getFormActionProviders = this.makeRequest({
    method: "GET",
    path: "/form-action-providers"
  });
  // Update required action
  updateRequiredAction = this.makeUpdateRequest({
    method: "PUT",
    path: "/required-actions/{alias}",
    urlParamKeys: ["alias"]
  });
  // Delete required action
  deleteRequiredAction = this.makeRequest({
    method: "DELETE",
    path: "/required-actions/{alias}",
    urlParamKeys: ["alias"]
  });
  // Lower required action’s priority
  lowerRequiredActionPriority = this.makeRequest({
    method: "POST",
    path: "/required-actions/{alias}/lower-priority",
    urlParamKeys: ["alias"]
  });
  // Raise required action’s priority
  raiseRequiredActionPriority = this.makeRequest({
    method: "POST",
    path: "/required-actions/{alias}/raise-priority",
    urlParamKeys: ["alias"]
  });
  // Get unregistered required actions Returns a list of unregistered required actions.
  getUnregisteredRequiredActions = this.makeRequest({
    method: "GET",
    path: "/unregistered-required-actions"
  });
  getFlows = this.makeRequest({
    method: "GET",
    path: "/flows"
  });
  getFlow = this.makeRequest({
    method: "GET",
    path: "/flows/{flowId}",
    urlParamKeys: ["flowId"]
  });
  getFormProviders = this.makeRequest({
    method: "GET",
    path: "/form-providers"
  });
  createFlow = this.makeRequest({
    method: "POST",
    path: "/flows",
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  copyFlow = this.makeRequest({
    method: "POST",
    path: "/flows/{flow}/copy",
    urlParamKeys: ["flow"]
  });
  deleteFlow = this.makeRequest({
    method: "DELETE",
    path: "/flows/{flowId}",
    urlParamKeys: ["flowId"]
  });
  updateFlow = this.makeUpdateRequest({
    method: "PUT",
    path: "/flows/{flowId}",
    urlParamKeys: ["flowId"]
  });
  getExecutions = this.makeRequest({
    method: "GET",
    path: "/flows/{flow}/executions",
    urlParamKeys: ["flow"]
  });
  addExecution = this.makeUpdateRequest({
    method: "POST",
    path: "/flows/{flow}/executions",
    urlParamKeys: ["flow"]
  });
  addExecutionToFlow = this.makeRequest({
    method: "POST",
    path: "/flows/{flow}/executions/execution",
    urlParamKeys: ["flow"],
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  addFlowToFlow = this.makeRequest({
    method: "POST",
    path: "/flows/{flow}/executions/flow",
    urlParamKeys: ["flow"],
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  updateExecution = this.makeUpdateRequest({
    method: "PUT",
    path: "/flows/{flow}/executions",
    urlParamKeys: ["flow"]
  });
  delExecution = this.makeRequest({
    method: "DELETE",
    path: "/executions/{id}",
    urlParamKeys: ["id"]
  });
  lowerPriorityExecution = this.makeRequest({
    method: "POST",
    path: "/executions/{id}/lower-priority",
    urlParamKeys: ["id"]
  });
  raisePriorityExecution = this.makeRequest({
    method: "POST",
    path: "/executions/{id}/raise-priority",
    urlParamKeys: ["id"]
  });
  getConfigDescription = this.makeRequest({
    method: "GET",
    path: "config-description/{providerId}",
    urlParamKeys: ["providerId"]
  });
  createConfig = this.makeRequest({
    method: "POST",
    path: "/executions/{id}/config",
    urlParamKeys: ["id"],
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  updateConfig = this.makeRequest({
    method: "PUT",
    path: "/config/{id}",
    urlParamKeys: ["id"]
  });
  getConfig = this.makeRequest({
    method: "GET",
    path: "/config/{id}",
    urlParamKeys: ["id"]
  });
  delConfig = this.makeRequest({
    method: "DELETE",
    path: "/config/{id}",
    urlParamKeys: ["id"]
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}/authentication",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
}