export const environment = {
    api : {
      domain : 'wbce.lamiche.v3.webcapsule.io',
      protocol : 'https',
      port : undefined
    },
    keycloak: {
      domain : 'keycloak.lamiche.v3.webcapsule.io',
      protocol : 'https',
      port: undefined
    },
    production: true,
    mock : false,
    directusOnly : false,
    googleTagID : '',
    clarity : '',
    datadog : undefined
};


