import { contextes, sizeConfig } from "../enums/contextes"
import { statusCommit } from "../enums/status"
import { technosName } from "../enums/technos"
import { o } from "../service/outils"
import { GitRepo } from "./git-repo"
import { Techno } from "./techno"

// add to a file
export enum report_types {
  LIGTHOUSE = 0
}

export enum serviceStates{
  DEAD = 0,
  WAITING = 1,
  RUNNING = 2
}

export enum deploymentStates{
  IN_PROGRESS,
  SUCCESS,
  ERROR,
  WARNING // only on front
}

export enum commitStates{
  NOT_DEPLOYED,
  DEPLOYING,
  DEPLOYED
}


export interface ApiError {
  name: string;
  message: string;
  stack?: string;
  code?: number;
}


export interface ReportSchema {
  type : number,
  data : any,
  updatedAt? : Date,
  createdAt? : Date
}

export interface Commit{
  ctxtToDeploy : contextes,
  createdAt : Date,
  message : string,
  commitId : string,
  userId? : string, //mock
  mergeRequests? : MergeRequest[],
  state? : statusCommit
}

export interface MergeRequest {
  sourceCommitId: string,
  weburl: string,
  status: PRStatus,
  has_conflict?: boolean
}

export enum PRStatus {
  OPEN = "open",
  CLOSED = "closed",
  DRAFT = "draft",
}

export interface Deployment{
  currentCommit : string,
  deployedBranch : string,
  message : ApiError,//confusing, fix this later
  deployedAt : Date,
  user: string
}

export interface DeploymentsHistory {
  newCommit : string,
  oldCommit : string,
  state : deploymentStates,
  message : string,
  createdAt : Date,
  updatedAt : Date,
  user:string
}

export class Contexte{
  deployment : Deployment
  deployments : DeploymentsHistory[]
  commits : Commit[]
  infra : any
  reports : {_id, createdAt}[] //ReportSchema ??
  domains : string[]
  isAdmin : boolean
  mainCycle : {
    message : string,
    state : deploymentStates
  }
  git : {
    branch : string
  }
  name : string
  sizeConfig : sizeConfig
}

export class Task{
  stoppedAt : Date
  createdAt : Date
  _id : string
  taskArn : string
  logs : {
    timestamp : number
    message : string
  }[]
  state : string
}



export interface Role {
  userId? : number;
  user? : {
    name? : string,
    email? : string,
    date? : string
  },
  roleByService? : {
    [key: string] : string[],
  },
  services? : number[]
}

  export interface DomainConfig{
    path : string;
    prefix: string;
    isRoot : boolean;
  }

  export class Service{
    _id : string;
    git : GitRepo;
    gitWBCE : GitRepo;
    techno : Techno;
    serviceName : string;
    state : deploymentStates;
    displayName: string;
    domainConfig: DomainConfig;
    updatedAt : Date;
    [contextes.PRODUCTION] : Contexte;
    [contextes.STAGING] : Contexte;
    onDemandContextes: Contexte[] = [];
   // [contextes.PREVIEW] : Contexte;
    roles? : Role[]; //mock
    caculatedDisplayName? : string = "";

    constructor(){

    }

    static createFromApi(infos){
      const service = new Service();
      o.deepCopy( infos, service);

      service.caculatedDisplayName = service.getDisplayName();
      return service;
    }

    getDisplayName():string{
      if(this.displayName && (this.displayName!=="")) return this.displayName;
      if(!this.serviceName || (this.serviceName === "front") ||(this.serviceName === "api") ||(this.serviceName === "workflow") || (this.serviceName==="") )return technosName[this.techno.id];
      return this.serviceName;
    }

    getIcon(): string{
      return technosName[this.techno.id];
    }

    getTechno():string{
      return technosName[this.techno.id];
    }

    getContextDoc(context : contextes|string){
      let contextDoc = this[context];
      if(!contextDoc){
        contextDoc = this.onDemandContextes.find(c=>c.name === context);
      }
      return contextDoc;
    }

    getBranchName(context:contextes | string):string{

      let gitConfig = this.gitWBCE
      let branchName = gitConfig[context+"Branch"]
      if(!branchName){
        let contextDoc = this.onDemandContextes.find(c=>c.name === context);
        branchName = contextDoc?.git.branch
      }
      return branchName;
    }

    isDisabled(ctxt?:contextes|string):boolean{

      if(ctxt){
        let deployments = this.getContextDoc(ctxt)?.deployments
        return (!deployments || (deployments.length===0)) // no deployment
      }

      return false;
    }

  }

