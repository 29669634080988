import { Project } from "./project";
import { contexteAffichage } from "../enums/contextes";
import { ROLE_SEP } from "../shared-components/wbce-button/wbce-button.component";
import { wbceKeycloakScopes } from "../service/keycloak.service";

export interface UserApiReturn{
    projects : Project[],
    userInfo? : UserInfo,//see KeycloakProfile to convert
}

export interface UserInfo {
  exp:                number;
  iat:                number;
  auth_time:          number;
  jti:                string;
  iss:                string;
  aud:                string[];
  sub:                string;
  typ:                string;
  azp:                string;
  nonce:              string;
  session_state:      string;
  name:               string;
  given_name:         string;
  family_name:        string;
  preferred_username: string;
  email:              string;
  email_verified:     boolean;
  acr:                string;
  "allowed-origins":  string[];
  realm_access:       RealmAccess;
  resource_access:    ResourceAccess;
  scope:              string;
  sid:                string;
  client_id:          string;
  username:           string;
  active:             boolean;
  _id:                string;
}

export interface RealmAccess {
  roles: string[];
}

export interface ResourceAccess {
  "realm-management": RealmAccess;
  account:            RealmAccess;
}



export class User{
    projects : Project[] = [];
    userInfo? : UserInfo;
    permissions : Map<string, string>;
    managedTeam : boolean = false;

    constructor(){
    }

    static createFromApi(userApiDoc : UserApiReturn){
        const user = new User();

        user.userInfo = userApiDoc.userInfo //wait for api

        if(userApiDoc.projects){
            for(const sApi of userApiDoc.projects ){
              user.projects.push(
                  Project.createFromApi(sApi)
              )
          }
        }
        user.managedTeam = false
        if(user.userInfo?.resource_access && user.userInfo.resource_access["realm-management"]){
          user.managedTeam = user.userInfo?.resource_access["realm-management"]?.roles.includes("manage-realm");
        }

        user.computePermissions();
        return user;
    }

    computePermissions(){
      let permissions =  new Map();
      this.projects?.forEach((p)=>{
        if(p.services?.length>0){ // we take the first, but later we'll take by services
          p.getContextes().forEach((c)=>{
            const contextDoc = p.services[0].getContextDoc(c);
            if(contextDoc){
              let permissionName = p._id + ROLE_SEP + c
              let scopes = []

              if(contextDoc.isAdmin){
                scopes = Object.keys(wbceKeycloakScopes)
              } else { // read only
                scopes = [wbceKeycloakScopes.GET]
              }

              permissions.set(permissionName, scopes)
            }

          })

        }
      })

      this.permissions = permissions
    }

    hasAccess(role:string) : boolean{
      let res = role.length === 0; // default no permission
      if(role === "manage-team"){
        res  = this.managedTeam;
      } else {
        const [projectId, ctxt, action] = role.split(ROLE_SEP);

        if(this.permissions && !res){
          let listCtxt  = [ctxt, "all"]
          let listProject = [projectId, "all"]

          for(const project of listProject){
            for(const ctxt of listCtxt){
              const mapKey = project + ROLE_SEP + ctxt
              const scopes = this.permissions.get(mapKey)
              if(scopes){
                res = scopes.includes(action)
                if(res) return true;
              }
            }
          }
        }
      }
      return res
    }


    getTooltipAccess(role :string) : string {


      function convertAction(action:string){
        if(action === wbceKeycloakScopes.GET){
          return "Read"
        } else {
          return "Admin"
        }
      }

      let tooltipWithAut = "";
      if(role === "manage-team"){
        tooltipWithAut = tooltipWithAut + "You need to have Owner role"
      } else {
        const [projectId, ctxt, action] = role.split(ROLE_SEP);
        tooltipWithAut = tooltipWithAut + "You cannot perform this action. You need "+convertAction(action)+" permission on "
        tooltipWithAut =tooltipWithAut + (ctxt==="all" ?' all environments' : contexteAffichage[ctxt])
        tooltipWithAut =tooltipWithAut +  (projectId==="all" ?' and on all projects' : '')
        tooltipWithAut =tooltipWithAut + "."
      }
      return tooltipWithAut
    }
}
