
export interface NavigationItem {
    name:string,
    route:string,
    shape:string,
    color:string,
    workinprogress?:boolean,
    readRole?:boolean,
  }

  export type Navigation = NavigationItem[]

export const navigationManageRole =
[
 /*{
    name: "Overview",
    route: "overview",
    shape: "triangle",
    color: "noir"
  },*/{
    name: "Users",
    route: "users",
    shape: "polygone",
    color: "purple"
  },{
    name: "Roles",
    route: "roles",
    shape: "trapeze",
    color: "yellow"
  }
]


export const navigationGlobal : Navigation =
  [
    {
      name: "Overview",
      route: "overview",
      shape: "triangle",
      color: "noir"
    },{
      name: "Version",
      route: "version",
      shape: "polygone",
      color: "purple"
    },{
      name: "Monitoring",
      route: "monitoring",
      shape: "rectangle",
      color: "red"
    },{
      name: "Workflows",
      route: "infra",
      shape: "trapeze",
      color: "yellow"
    },{
      name: "Preferences",
      route: "preferences",
      shape: "cercle",
      color: "orange",
      readRole : true
    }

  ]


  export const navigationService : Navigation =
  [
{
      name: "Version",
      route: "version",
      shape: "polygone",
      color: "purple"
    },{
      name: "Workflows",
      route: "infra",
      shape: "trapeze",
      color: "yellow"
    },{
      name: "Preferences",
      route: "preferences",
      shape: "cercle",
      color: "orange",
      readRole : true
    }

  ]


export const app_name = "Webcapsule"
export const app_logo = "webcapsule"

export const app_title = "My "+app_name+" Cloud"

export interface PathType {
  title : string,
  description? : string,
  color? : string
}

export interface NavigationDescription {
  [path : string]:PathType
}

export const navigationDescription =  {
  repo : {
    title : "Adding project",
    color : "#FF9960"// $wbce-orange
  },
  home : {
    title : "Project management",
    color : "#FF9960"//$wbce-orange
  },
  overview : {
    title : "Global visualization",
    color : "#38D4CC" //$wbce-green
  },
  infra : {
    title : "Workflows and actions",
    color : "#FFF176"//$wbce-yellow
  },
  performances : {
    title : "Check performances",
    color : "#FF576D"//$wbce-red
  },
  monitoring : {
    title : "Report",
    color : "#FF576D"//$wbce-red
  },
  version : {
    title : "Version tracking",
    color : "#7C8AE8" //$wbce-purple
  },
  security : {
    title : "Security management",
    color : "#FF9960" //$wbce-orange
  },
  preferences : {
    title : "Project settings",
    color : "#FF9960" //$wbce-orange
  },
  'manage-account' : {
    title : "Account management",
    color : "#FF9960"
  },
  'workspace' : {
    title : "Workspace settings",
    color : "#FF9960"
  },
  'hosting' : {
    title : "Workspace settings",
    color : "#FF9960"
  },
  'biling' : {
    title : "Workspace settings",
    color : "#FF9960"
  },
  'plan' : {
    title : "Workspace settings",
    color : "#FF9960"
  },
  'user' : {
    title : "Manage team",
    color : "#FF9960"
  },
  'users' : {
    title : "Manage team - users",
    color : "#7C8AE8"
  },
  'roles' : {
    title : "Manage team - roles",
    color : "#FFF176"
  },
  'create-account' : {
    title : "Create account",
    color : "#FF9960"
  },
  'create-server' : {
    title : "Create server",
    color : "#FF9960"
  },
  default : {
    title : "404",
    description : "File not found"
  }
};

