export enum technos{
    NOCODESTACK=-2,
    AUTRE = -1,
    LARAVEL=1,
    ANGULAR=2,
    JAVA=3,
    NODETS=4,
    GENERIC_FRONT=5,
    WEWEB=6,
    DIRECTUS=7,
    STRAPI=8,
    N8N=9,
    WEBCAPSULE=10,
    KEYCLOAK=11,
    INFISICAL=12,
    XANO=13,
    BLANK=14,
    WEBFLOW=101,
    SUPABASE=103,
    POSTGRESQL=105,
    FLUTTERFLOW=106,
    LLAMA=107,
    METABASE=108,
    PLASMIC=109,
}


export const technosName = {
  [technos.ANGULAR] : "angular",
  [technos.WEWEB] : "weweb",
  [technos.DIRECTUS] : "directus",
  [technos.SUPABASE] : "supabase",
  [technos.N8N] : "n8n",
  [technos.WEBFLOW] : "webflow",
  [technos.POSTGRESQL] : "postgresql",
  [technos.STRAPI] : "strapi",
  [technos.FLUTTERFLOW] : "flutterflow",
  [technos.LLAMA] : "llama",
  [technos.METABASE] : "metabase",
  [technos.PLASMIC] : "plasmic",
  [technos.XANO] : "xano",
  [technos.BLANK] : "custom",
}

